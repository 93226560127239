<template>
  <div class="home-overview" :style="{'height':($root.docHeight-135)+'px'}" v-loading="loading">
    <div class="forecast-tabs margin-bottom-20">
      <div :class="{'active':modelFreq=='week'}" @click="modelFreq='week'">周度预测模型</div>
      <div :class="{'active':modelFreq=='month'}" @click="modelFreq='month'">月度预测模型</div>
    </div>
    <template v-if="info.sell">
      <div class="font-size-20 margin-bottom-15">销售产品</div>
      <el-row :gutter="20" type="flex">
        <el-col :span="8" v-for="(item,index) in info.sell" :key="index">
          <div class="column">
            <div class="flex-row justify-between align-items-center">
              <div class="link font-size-20" @click="gotoDetail(item)">{{item.productName}}</div>
              <div class="link-text" @click="gotoDetail(item)">查看详情</div>
            </div>
            <div class="color-sub font-size-12 margin-top-5">预测周期：{{item.startTime}} 至 {{item.endTime}}</div>
            <div class="color-ra-sub font-size-12 margin-top-15">
              <el-popover
                placement="bottom-start"
                width="500"
                trigger="hover">
                <div class="font-size-12 line-height-large">
                  <div><span class="color-sub">数据名称：</span>{{ item.desc.name }}</div>
                  <div><span class="color-sub">数据单位：</span>{{ item.desc.unit }}</div>
                  <div><span class="color-sub">市场名称：</span>{{ item.desc.market }}</div>
                  <div><span class="color-sub">规格牌号：</span>{{ item.desc.dataModel }}</div>
                  <div><span class="color-sub">更新频率：</span>{{ item.desc.frequency }}</div>
                  <div><span class="color-sub">统计日期：</span>{{ item.desc.time }}</div>
                  <div><span class="color-sub">样本说明：</span>{{ item.desc.explain }}</div>
                  <div><span class="color-sub">方法论：</span>{{ item.desc.func }}</div>
                  <div><span class="color-sub">数据来源：</span>{{ item.desc.dataSource }}</div>
                </div>
                <span slot="reference" class="link">{{item.targetName}}</span>
              </el-popover>
            </div>
            <div class="flex-row justify-between margin-top-5">
              <div>
                <div class="color-sub font-size-12">{{item.showData.dataCycle}}</div>
                <div class="color-sub font-size-12">{{modelFreq=='week'?'周':'月'}}预测（模型自估预测）</div>
                <div class="font-size-20">{{item.showData.ai | numFormat(2,'-')}}</div>
              </div>
              <div>
                <div class="color-sub font-size-12">{{item.showData.dataCycle}}</div>
                <div class="color-sub font-size-12">{{modelFreq=='week'?'周':'月'}}预测（卓创赋值预测）</div>
                <div class="font-size-20">{{item.showData.sci | numFormat(2,'-')}}</div>
              </div>
              <div>
                <div class="color-sub font-size-12">{{item.showData.dataCycle}}</div>
                <div class="color-sub font-size-12">{{modelFreq=='week'?'周':'月'}}预测（滨化赋值预测）</div>
                <div class="font-size-20">{{item.showData.befar | numFormat(2,'-')}}</div>
              </div>
            </div>
            <Chart :option="chart_opt({items:item.items,dataList:item.dataList})"></Chart>
          </div>
        </el-col>
      </el-row>
    </template>
    <template v-if="info.buy">
      <div class="font-size-20 margin-bottom-15">采购产品</div>
      <el-row :gutter="20" type="flex">
        <el-col :span="8" v-for="(item,index) in info.buy" :key="index">
          <div class="column">
            <div class="flex-row justify-between align-items-center">
              <div class="link font-size-20" @click="gotoDetail(item)">{{item.productName}}</div>
              <div class="link-text" @click="gotoDetail(item)">查看详情</div>
            </div>
            <div class="color-sub font-size-12 margin-top-5">预测周期：{{item.startTime}} 至 {{item.endTime}}</div>
            <div class="color-ra-sub font-size-12 margin-top-15">
              <el-popover
                placement="bottom-start"
                width="500"
                trigger="hover">
                <div class="font-size-12 line-height-large">
                  <div><span class="color-sub">数据名称：</span>{{ item.desc.name }}</div>
                  <div><span class="color-sub">数据单位：</span>{{ item.desc.unit }}</div>
                  <div><span class="color-sub">市场名称：</span>{{ item.desc.market }}</div>
                  <div><span class="color-sub">规格牌号：</span>{{ item.desc.dataModel }}</div>
                  <div><span class="color-sub">更新频率：</span>{{ item.desc.frequency }}</div>
                  <div><span class="color-sub">统计日期：</span>{{ item.desc.time }}</div>
                  <div><span class="color-sub">样本说明：</span>{{ item.desc.explain }}</div>
                  <div><span class="color-sub">方法论：</span>{{ item.desc.func }}</div>
                  <div><span class="color-sub">数据来源：</span>{{ item.desc.dataSource }}</div>
                </div>
                <span slot="reference" class="link">{{item.targetName}}</span>
              </el-popover>
            </div>
            <div class="flex-row justify-between margin-top-5">
              <div>
                <div class="color-sub font-size-12">{{item.showData.dataCycle}}</div>
                <div class="color-sub font-size-12">{{modelFreq=='week'?'周':'月'}}预测（模型自估预测）</div>
                <div class="font-size-20">{{item.showData.ai | numFormat(2,'-')}}</div>
              </div>
              <div>
                <div class="color-sub font-size-12">{{item.showData.dataCycle}}</div>
                <div class="color-sub font-size-12">{{modelFreq=='week'?'周':'月'}}预测（卓创赋值预测）</div>
                <div class="font-size-20">{{item.showData.sci | numFormat(2,'-')}}</div>
              </div>
              <div>
                <div class="color-sub font-size-12">{{item.showData.dataCycle}}</div>
                <div class="color-sub font-size-12">{{modelFreq=='week'?'周':'月'}}预测（滨化赋值预测）</div>
                <div class="font-size-20">{{item.showData.befar | numFormat(2,'-')}}</div>
              </div>
            </div>
            <Chart :option="chart_opt({items:item.items,dataList:item.dataList})"></Chart>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import Chart from "@/components/Chart";
import crypto from "@/common/crypto";
export default {
  name: 'overview',
  components:{
    Chart
  },
  data (){
    return{
      modelFreq:'week',
      loading:false,
      info:{},//数据
    }
  },
  computed:{
    chart_opt(){
      return (res)=>{
        let chartData=res
        if(chartData.items){
          let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
          opt.yAxis.name=chartData.items[0].unit
          opt.xAxis.boundaryGap = false
          opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
          // opt.dataZoom.startValue=opt.xAxis.data.length-15
          opt.legend.data=chartData.items.map(v=>{
            return {
              name:v.name,
              unit:v.unit,
              itemStyle:{
                  opacity:0.001
              }
            }
          })
          opt.series=chartData.items.map(v=>{
            return {
              name: v.name,
              id:v.id,
              type: 'line', 
              connectNulls:true,
              lineStyle:{
                type:v.id=='his'?"solid":"dashed",
              },
              data: chartData.dataList.map(vv=>{return vv[v.id]}),
            }
          }) 
          opt.tooltip.formatter = (params)=>{
            let str=`<div>${params[0].axisValue}</div>`
            params.forEach((val)=>{
              if(this.$numFormat(val.data)){
                let item=chartData.items[val.seriesIndex]
                if(item.id!='his'){
                  if(!this.$numFormat(chartData.dataList[val.dataIndex]['his'])||!params.some(v=>v.seriesId=='his')){
                    str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                  }
                }else{
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }
            })
            return str
          }
          return opt
        }else{
          return null
        }
      }
    }
  },
  beforeCreate(){
    
  },
  created(){
    this.getData()
  },
  mounted(){
    
  },
  methods:{
    //获取数据
    getData(){
      this.loading=true
      let request=this.$instance.get('/index/getOverviewChart',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.info=res.data.info
        }else{
          this.info={}
        }
      })
      request.catch(()=>{
        this.loading=false
        this.info={}
      })
      return request
    },
    gotoDetail(item){
      let q=crypto.encrypt({targetId:item.targetId,productId:item.productId,productName:item.productName}),
          path=this.modelFreq=="week"?"/forecast/weekModel":"/forecast/monthModel"
      this.$router.push({
        path: path,
        query:{q}
      })
    }
  },

  watch: {
    'modelFreq'(){
      this.getData()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
  .forecast-tabs{
    display: flex;
    @include themeify{
        border-bottom:themed('borderColor') solid 1px;
    };
    div{
      padding: 5px 25px;
      border-radius: 5px 5px 0px 0px/5px 5px 0px 0px;
      cursor: pointer;
    }
    div.active{
      background: #004ea3;
      color: #fff;
    }
  }
 
</style>